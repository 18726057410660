<script>
import axios2 from "@/api";

export default {
  name: "infoExport",
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      school_id_info: '',
      grade_id_info: '',
      class_id_info: '',
      school_options: [],
      grade_options: [],
      class_options: [],
      info_checkAll: false,
      info_isIndeterminate: true,
      info_selected: [],
      baseInfo: {
        show_base: [],
        show_base_info: [],
      },
    }
  },
  created() {
    axios2.get('api2/api/common/export/conf').then(res => {
      this.baseInfo = res
    })
    this.$_axios2('/api/common/screen/school').then(res => {
      this.school_options = res.data.data.map(item => ({
        value: item.id,
        label: item.school_name
      }))
    })
    this.class_options = [{label: '全部', value: 0}]
    this.$_axios2('/api/common/screen/admin-class', {
      params: {
        school_id: this.school_id_info,
        grade_id: this.grade_id_info,
      }
    }).then(res => {
      let arr = res.data.data.map(item => ({
        value: item.id,
        label: item.class_name
      }))
      this.class_options = this.class_options.concat(arr)
    })
  },
  methods: {
    getGrade() {
      this.grade_id = ''
      this.grade_id_info = ''
      this.class_id_info = 0
      this.$_axios2('/api/common/screen/grade').then(res => {
        this.grade_options = res.data.data.map(item => ({
          value: item.id,
          label: item.grade_name
        }))
      })
    },
    getClass() {
      this.class_id_info = 0
      this.class_options = [{label: '全部', value: 0}]
      this.$_axios2('/api/common/screen/admin-class', {
        params: {
          school_id: this.school_id_info,
          grade_id: this.grade_id_info,
        }
      }).then(res => {
        let arr = res.data.data.map(item => ({
          value: item.id,
          label: item.class_name
        }))
        this.class_options = this.class_options.concat(arr)
      })
    },
    handleInfoCheckAllChange(val) {
      this.info_selected = val ? this.baseInfo.show_base.concat(this.baseInfo.show_base_info) : ['学生姓名', '学号'];
      // this.info_isIndeterminate = this.baseInfo.info_selected.length === 2;
    },
    handleCheckedInfoChange(value) {
      let checkedCount = value.length;
      let max_length = this.baseInfo.show_base.concat(this.baseInfo.show_base_info).length
      this.info_checkAll = checkedCount === max_length;
      this.info_isIndeterminate = checkedCount > 0 && checkedCount < max_length;
    },

    onSubmit2() {
      let params = {
        school_id: this.school_id_info,
        grade_id: this.grade_id_info,
        class_id: this.class_id_info,
        selections: JSON.stringify(this.info_selected),
      }
      this.$_axios2.post('api/common/export/student', params).then(res => {
        if (typeof res.data === 'string') {
          this.$message.success('导出成功')
          this.$tools.download('学生基本信息', res.data)
        }
      })
    },
    onClose2() {
      this.$emit('close')
      this.school_id_info = ''
      this.grade_id_info = ''
      this.class_id_info = 0
      this.grade_options = []
      this.class_options = [{label: '全部', value: 0}]
      this.info_selected = ['学生姓名', '学号']
      this.info_checkAll = false
      this.info_isIndeterminate = true
    },
  }
}
</script>

<template>
  <el-dialog :before-close="onClose2" :close-on-click-modal="false" :close-on-press-escape="false" :visible.sync="dialogVisible" custom-class="t-dialog-1004" title="学生基本信息导出">
    <div class="select-box">
      <div class="box-item">
        <span>校区：</span>
        <el-select v-model="school_id_info" class="w-160" placeholder="请选择校区" size="small" @change="getGrade">
          <el-option v-for="item in school_options" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </div>
      <div class="box-item">
        <span>年级：</span>
        <el-select v-model="grade_id_info" class="w-160" placeholder="请选择年级" size="small" @change="getClass">
          <el-option v-for="item in grade_options" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </div>
      <div class="box-item">
        <span>行政班：</span>
        <el-select v-model="class_id_info" class="w-160" placeholder="请选择行政班" size="small">
          <el-option v-for="item in class_options" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </div>
    </div>
    <div class="main-box">
      <div class="left">
        <div class="flex justify-between">
          <p class="p3">选择导出字段</p>
          <el-checkbox v-model="info_checkAll" :indeterminate="info_isIndeterminate" @change="handleInfoCheckAllChange">全选</el-checkbox>
        </div>
        <div>
          <p class="p3 mt-20">基础信息</p>
          <div>
            <el-checkbox-group v-model="info_selected" @change="handleCheckedInfoChange">
              <el-checkbox v-for="item in baseInfo.show_base" :key="item" :checked="item === '学生姓名' || item === '学号'" :disabled="item === '学生姓名' || item === '学号'" :label="item">{{ item }}</el-checkbox>
            </el-checkbox-group>
          </div>
          <p class="p3 mt-20">招生信息</p>
          <div>
            <el-checkbox-group v-model="info_selected" @change="handleCheckedInfoChange">
              <el-checkbox v-for="item in baseInfo.show_base_info" :key="item" :label="item">{{ item }}</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <div class="right">
        <p class="p3">选择导出字段</p>
        <p class="p3 mt-20">基础信息</p>
        <p v-for="item in info_selected" v-if="baseInfo.show_base.includes(item)" class="p4">{{ item }}</p>
        <p class="p3 mt-20">招生信息</p>
        <p v-for="item in info_selected" v-if="baseInfo.show_base_info.includes(item)" class="p4">{{ item }}</p>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
        <el-button :disabled="!grade_id_info" type="primary" @click="onSubmit2">导 出</el-button>
        <el-button @click="onClose2">取 消</el-button>
      </span>
  </el-dialog>
</template>

<style lang="scss" scoped>

.select-box {
  display: flex;
  align-items: center;
}

.box-item {
  display: flex;
  align-items: center;
  margin-right: 40rem;

  span {
    color: #333;
    font-size: 16rem;
  }
}

.main-box {
  border: 1rem solid #DBDDE4;
  display: flex;
  margin-top: 30rem;

  .left {
    padding: 24rem;
    flex: 1;
    max-height: 465rem;
    overflow-y: scroll;
  }

  .right {
    width: 260rem;
    min-width: 260rem;
    padding: 24rem;
    border-left: 1rem solid #DBDDE4;
    max-height: 465rem;
    overflow-y: scroll;
  }
}

.p3 {
  color: #333;
  font-size: 16rem;
  font-weight: 500;
}

.p4 {
  font-size: 14rem;
  color: #666;
  margin-top: 12rem;
  padding-left: 12rem;
  position: relative;

  &:before {
    content: '';
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background-color: #1C4EFD;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}

.el-checkbox {
  width: 98rem;
}

.el-checkbox-group .el-checkbox {
  margin-top: 20rem;
}
</style>